.login {
  background-color: #f1f6f6;
  width: 100vw;
  height: 100vh;

  .login-illustration {
    background-color: #76b8ae;
    background-image: url('../../assets/illustration-scf.svg');
    background-size: 911px 693px;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

  .login-container {
    background-color: #ffffff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .login-logo {
      display: block;
      margin-bottom: 10px;
      width: 150px;
    }

    label {
      color: #4a4a4a;
      font-weight: 900;
    }

    .form-control {
      border-color: #d9d9d9;
    }

    .login-footer {
      font-size: 12px;
      color: #9b9b9b;
      padding: 20px 0 0;
      border-top: 1px solid #d8d8d8;
    }
  }
}