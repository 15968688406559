.profile {
  margin-top: 30px;

  .user-image {
    width: 80px;
    height: 80px;
    margin-right: 15px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    object-fit: cover;
  }

  .user-default {
    background-color: #e6e7e8; 
    padding: 15px; 
    border-radius: 4px; 
    margin-right: 15px;
    display: inline-block;

    img { 
      width: 30px;
    }
  }

  .MuiListItem-root {
    border-radius: 5px;
    
    &[aria-selected=true] {
      background-color: #27a397;
      color: #ffffff;
  
      img { 
        filter: invert(100%) brightness(200%);
      }
    }
  }

  .btn-photo-edit {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    top: -57px;
    right: 27px;
    text-align: center;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
      filter: grayscale(1) brightness(0);
    }
  }

  .change-photo {
    .user-image {
      width: 120px;
      height: 120px;
    }
    
    .user-default {
      padding: 24px 27px;

      img {
        width: 65px;
      }
    }
  }

  .btn-photo-delete {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    top: 57px;
    right: 50px;
    text-align: center;
    cursor: pointer;
    display: inline-block;

    img {
      width: 10px;
      height: 10px;
      filter: grayscale(1) brightness(0);
    }
  }
}