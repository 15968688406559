.payment-method-modal {
  .MuiAccordion-root {
    box-shadow: none;
    
    &:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }

    &:before {
      display: none;
    }

    &.Mui-expanded {
      margin: 0;
      min-height: 48px;
    }
    
    .MuiAccordionSummary-root {
      padding: 0;
    }

    .MuiAccordionDetails-root {
      padding: 0 0 15px;
    }
  }
  
}