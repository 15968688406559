.dashboard-summary {
  display: flex;
  color: #fff;

  &> div {
    flex: 1 1 0;
  }

  .MuiCard-root {
    color: #fff;
    margin-right: 30px;
    border-radius: 10px;
    min-width: 248px;
    background-repeat: no-repeat;
    background-position: right top;

    &.card-total {
      background-color: #f64e60;
      background-image: url('../../assets/icons/oval-red.svg');
    }
  
    &.card-funded {
      background-color: #1bc5bd;
      background-image: url('../../assets/icons/oval-green.svg');
    }
  
    &.card-paid {
      background-color: #16a9cc;
      background-image: url('../../assets/icons/oval-blue.svg');
      flex: 1 1 0;
    }
  }
}

.shipper_initial {
  border-radius: 4px;
  background-color: #e6e7e8;
  border: 1px solid #e6e7e8;
  font-size: 18px;
  color: #3caf9c;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  line-height: 2.3;
  cursor: pointer;
  text-transform: uppercase;
  object-fit: cover;
}

.MuiTabs-root {
  padding-left: 20px;
  
  .MuiTab-root {
    background-color: #E0E0E0;
    border-radius: 8px 8px 0 0;
    margin-left: 10px;

    &.Mui-selected {
      background-color: #fff;
      color: #27a397;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.level-badge-wrapper {
  white-space: nowrap;
  margin-bottom: 10px;
  
  &.inline {
    margin-left: 5px;
    display: inline-block;
  }
}

.level-badge {
  width: 18px !important;
  height: 20px !important;
  display: inline-block !important;
  background-size: auto;
  background-repeat: no-repeat;
  vertical-align: middle;

  &.bronze {
    background-image: url('../../assets/icons/rating-bronze.svg');
  }
  &.silver {
    background-image: url('../../assets/icons/rating-silver.svg');
  }
  &.gold {
    background-image: url('../../assets/icons/rating-gold.svg');
  }
  &.diamond {
    background-image: url('../../assets/icons/rating-diamond.svg');
    margin-right: 2px;
    height: 16px !important;
  }
  &.no_badge {
    background-image: url('../../assets/icons/rating-no_badge.svg');
  }
}
