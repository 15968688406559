/**
 * Avenir Fonts
 */

 @font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/AvenirLTStd-Book.woff") format("woff");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/AvenirLTStd-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/AvenirLTStd-Heavy.woff") format("woff");
}

html,
body {
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  background: #edfbf9;
  font-family: "Avenir", sans-serif;
  font-size: 14px;
}

img {
  vertical-align: middle;
  border-style: none
}

a {
  color: #27a397;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects
}

a:hover {
  color: #27a397;
  text-decoration: underline;
}

* {
  outline: none !important;
}

.s-12 {
  font-size: 12px !important;
}

.s-14 {
  font-size: 14px !important;
}

.s-16 {
  font-size: 16px !important;
}

.s-18 {
  font-size: 18px !important;
}

.s-22 {
  font-size: 22px !important;
}

.s-24 {
  font-size: 24px !important;
}

.s-36 {
  font-size: 36px !important;
}

.s-48 {
  font-size: 48px !important;
}

.font-weight-lighter, .thin {
  font-weight: 100 !important;
}

.bolder, .font-weight-bolder {
  font-weight: 900 !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-ritase {
  color: #27A397 !important;
}

.text-grey {
  color: #9b9b9b !important;
}

.text-black {
  color: #000000 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-warning {
  color: #ebaf01 !important;
}

.text-action {
  color: #f16b27 !important;
}

.text-danger {
  color: #f23d3d !important;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.bg-white {
  background-color: #fff !important
}

.bg-transparent {
  background-color: transparent !important
}

.border {
  border: 1px solid #e0e0e0 !important
}

.border-top {
  border-top: 1px solid #e0e0e0 !important
}

.border-right {
  border-right: 1px solid #e0e0e0 !important
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0 !important
}

.border-left {
  border-left: 1px solid #e0e0e0 !important
}

.border-0 {
  border: 0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #f27130 !important
}

.border-secondary {
  border-color: #6c757d !important
}

.border-success {
  border-color: #7dc855 !important
}

.border-info {
  border-color: #78bcee !important
}

.border-warning {
  border-color: #fcce54 !important
}

.border-danger {
  border-color: #ed5564 !important
}

.border-light {
  border-color: #f8f9fa !important
}

.border-dark {
  border-color: #272c33 !important
}

.border-white {
  border-color: #fff !important
}

.border-dashed {
  border: 1px dashed #e0e0e0 !important
}

.border-dashed-top {
  border-top: 1px dashed #e0e0e0 !important
}

.border-dashed-right {
  border-right: 1px dashed #e0e0e0 !important
}

.border-dashed-bottom {
  border-bottom: 1px dashed #e0e0e0 !important
}

.border-dashed-left {
  border-left: 1px dashed #e0e0e0 !important
}

.pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.container-content {
  width: 100%;
  min-height: 500px;
  margin: 0;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.panel {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #fff;
}

.panel-header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 18px;
}

.panel-body {
  padding: 20px;
}

.panel-footer {
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  text-align: right;
}

.table-card table {
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
  /* white-space: nowrap; */
}

.table-card thead th {
  color: #9b9b9b;
  border-bottom: none;
  padding: 0 16px;
  font-size: 14px;
}

.table-card tr {
  vertical-align: top;
}

.table-card tr.Mui-selected, .table-card tr.Mui-selected:hover {
  background-color: #d4edea;
}

.table-card tr td {
  border: none;
  font-size: 14px;
}

.table-card tbody td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.table-card tbody td:first-child {
  border-left: 1px solid #e0e0e0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-card tbody td:last-child {
  border-right: 1px solid #e0e0e0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* override react-datepicker */
.react-datepicker {
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  font-family: 'Avenir';
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #27a397;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #fff;
  border: none;
}

.react-datepicker__current-month {
  margin-bottom: 15px;
}

.react-datepicker__day--outside-month {
  color: #bfbfbf;
}

.filter-container {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-list {
  max-width: 80vw;
  overflow-x: scroll;
  display: flex;
  white-space: nowrap;
  margin-right: 30px;
  align-items: center;
}

.filter-list::-webkit-scrollbar {
  display: none;
}

.filter-item {
  border-radius: 18px;
  border: solid 1px #d9d9d9;
  background-color: #fff;
  color: #595959;
  padding: 8px 12px;
  margin-right: 10px;
  text-align: center;
}

.filter-item[aria-selected=true] {
  background-color: #27a397;
  color: #ffffff;
}

.footer {
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-group.location label {
  color: #9e9e9e;
  display: block;
  margin-bottom: .1rem;
}

.list-group.location li {
  border: none;
  overflow: hidden;
}

.list-group.location li:last-child {
  padding-top: 0;
}  

.list-group.location li::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #27a397;
  top: 18px;
  position: absolute;
  left: 0;
}

.list-group.location li:last-child::before {
  top: 6px;
}

.list-group.location li:not(:last-child)::after {
  content: '';
  width: 2px;
  background: #e0e0e0;
  height: 100%;
  position: absolute;
  left: 4px;
  top: 45px;
  margin: -10px 0;
}

.list-group.location li:not(:first-child)::before {
  background-color: #27a397;
}

.status-badge {
  border-radius: 20px;
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 900;
  color: #ffffff;
  display: inline-block;
}

.status-badge.black {
  background-color: #595959;
}

.status-badge.blue {
  background-color: #009abf;
}

.status-badge.red {
  background-color: #f23d3d;
}

.status-badge.yellow {
  background-color: #ffbe00;
  color: #000000;
}

.status-badge.green {
  background-color: #28a94a;
}

.MuiDialog-paper {
  position: absolute !important;
  left: 46% !important;
  top: 0;
  transform: translate(-46%, 0) !important;
}

.MuiPagination-ul {
  justify-content: flex-end;
}

.form-control:focus {
  box-shadow: none;
}

input::placeholder {
  color: #d0d0d0 !important;
}

textarea::placeholder {
  color: #d0d0d0 !important;
}

.image-gallery-slide .image-gallery-image {
  width: 40vw;
  margin-bottom: 20px;
}

.swal2-container {
  z-index: 9999 !important;
}

.badge-ritase {
  background-color: #27a397;
  color: #fff;
  margin-left: 5px;
  vertical-align: middle;
  padding: 5px 8px 3px;
}

.dropzone {
  border: 1px dashed #3CAF9C;
  border-radius: 5px;
  text-align: center;
  padding: 30px;
  font-size: 16px;
  margin-top: 12px;
  cursor: pointer;
}