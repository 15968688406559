.root {
  display: flex;

  .menuButton {
    margin-right: 60px;
  }

  .hide {
    display: none;
  }

  .activeMenu {
    background-color: #f8f8fb;
    color: #27a397;
    box-shadow: none;
    padding: 8px 32px;
  }

  .inactiveMenu {
    background-color: #fff;
    color: #404040;
    box-shadow: none;
    padding: 8px 32px;
  }

  .MuiButtonBase-root.MuiIconButton-root {
    outline: none;
  }

  .logo {
    width: 100px;
  }
}

.header-initial {
  border-radius: 4px;
  background-color: #edfbf9;
  font-size: 18px;
  color: #3caf9c;
  text-align: center;
  display: inline-block;
  margin-left: 1rem;
  width: 32px;
  height: 32px;
  line-height: 1.9;
  cursor: pointer;
}

.header-dropdown {
  width: 250px;
  z-index: 9999;

  a {
    color: #404040 !important;
    text-decoration: none !important;
  }
}